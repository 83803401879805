import { useState, Fragment } from 'react'
import API from 'api'
import messages from '../../messages'
import { FormattedMessage, useIntl } from 'react-intl'
import './styles/padi-autosuggest.scss'
import { APP_LANGUAGE } from '../../localized-urls'
import debounce from 'lodash/debounce'
import { trackClick } from './gtm-events'

const { PADI_AUTOCOMPLETE_URL } = process.env

function PadiSearch() {
  const intl = useIntl()
  const [results, setResults] = useState([])
  const [value, setValue] = useState('')
  const [isFocused, setFocus] = useState(false)
  const languagePrefix = APP_LANGUAGE === 'en' ? '' : APP_LANGUAGE + '/'
  const getData = debounce((q) => {
    API(PADI_AUTOCOMPLETE_URL)
      .get({
        display: 'page',
        filter: 'keywords',
        q,
      })
      .then((data) => {
        setResults(data)
      })
  }, 300)
  const search = ({ target }) => {
    setValue(target.value)
    getData(target.value)
  }
  const onResultClick = (item) => {
    const url = `https://www.padi.com/${languagePrefix}search?keywords=${item.value}`
    trackClick({ regionIdentifier: 'menu-main-search', link: url })
    window.location.href = url
  }

  const onInputFocus = () => {
    setFocus(true)
  }
  const onInputBlur = () => {
    setTimeout(() => {
      setFocus(false)
    }, 200)
  }
  const cancelClick = () => {
    const body = document.querySelector('body')
    const searchContainer = document.querySelector('#search-container')
    body.classList.remove('body-hidden')
    searchContainer.classList.remove('visible-search')
    setValue('')
    setResults([])
  }
  const handleFormSubmit = (e) => {
    e.preventDefault()
    window.location.href = `https://www.padi.com/${languagePrefix}search?keywords=${value}`
    return false
  }

  return (
    <Fragment>
      <i className='padi-icons padi-icons--search padi-nav-header__input-icon' />
      <form
        className='padi-nav-header__search-input-container'
        onSubmit={handleFormSubmit}
      >
        <input
          name='search'
          type='text'
          className='padi-nav-header__search-input'
          onChange={search}
          autoComplete='off'
          value={value}
          placeholder={intl.formatMessage(messages.search)}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        {!!results.length && isFocused && (
          <div className='padi-nav-header__autosuggest-results'>
            {results.map((result) => {
              const handleClick = () => {
                onResultClick(result)
              }
              return (
                <div
                  tabIndex='-1'
                  dangerouslySetInnerHTML={{ __html: result.label }}
                  className='padi-nav-header__autosuggest-item'
                  onClick={handleClick}
                  key={result.value}
                />
              )
            })}
          </div>
        )}
      </form>

      <span
        className='padi-nav-header__input-cancel'
        id='cancel-search'
        onClick={cancelClick}
      >
        <FormattedMessage id='cancel' defaultMessage='Cancel' />
      </span>
    </Fragment>
  )
}

export default PadiSearch
