import { Fragment } from 'react'
import store2 from 'store2'
import { isLoggedIn } from '../../../utils'
import UserLoggedInPro from './userLoggedInPro'
import UserLoggedIn from './userLoggedIn'
import UserNotLoggedIn from './userNotLoggedIn'

export default function PadiHeaderAccountSection() {
  const isUserAuthenticated = isLoggedIn()
  const user = store2.get('user')
  return <Fragment>
    {isUserAuthenticated
      ? (user?.isProDiver
        ? (<UserLoggedInPro user={user} />)
        : (<UserLoggedIn user={user} />)
      )
      : (<UserNotLoggedIn />)
    }
  </Fragment>
}
