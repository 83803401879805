import { createAction } from 'redux-actions'
import { parseQuery } from '../utils'

const queryParams = parseQuery()

const SET_SELECTED_PIN = 'SET_SELECTED_PIN'
const setSelectedPin = createAction(SET_SELECTED_PIN)

function selectedPin(state = null, { type, payload }) {
  switch (type) {
    case SET_SELECTED_PIN:
      return payload
    default:
      return state
  }
}

const SET_IS_MAP_VISIBLE = 'SET_IS_MAP_VISIBLE'
const setIsMapVisible = createAction(SET_IS_MAP_VISIBLE)

function isMapVisible(state = true, { type, payload }) {
  switch (type) {
    case SET_IS_MAP_VISIBLE:
      return payload
    default:
      return state
  }
}

const SET_OPENNED_MARKER = 'SET_OPENNED_MARKER'
const setOpennedMarker = createAction(SET_OPENNED_MARKER)

function opennedMarker(state = null, { type, payload }) {
  switch (type) {
    case SET_OPENNED_MARKER:
      return payload
    default:
      return state
  }
}

const SET_SHOP_INFO = 'SET_SHOP_INFO'
const setShopInfo = createAction(SET_SHOP_INFO)

function shopInfo(state = {}, { type, payload }) {
  switch (type) {
    case SET_SHOP_INFO:
      return payload
    default:
      return state
  }
}

const SET_FILTERS = 'SET_FILTERS'
const UPDATE_FILTERS = 'UPDATE_FILTERS'
const setFilters = createAction(SET_FILTERS)
const updateFilters = createAction(UPDATE_FILTERS)
function mapFilters(state = queryParams, { type, payload }) {
  switch (type) {
    case SET_FILTERS:
      return payload
    case UPDATE_FILTERS:
      return { ...state, ...payload }
    default:
      return state
  }
}

const SET_TAB_TYPE = 'SET_TAB_TYPE'
const setTabType = createAction(SET_TAB_TYPE)

function selectedTab(
  state = window.selectedTab || 'dive-centers',
  { type, payload },
) {
  switch (type) {
    case SET_TAB_TYPE:
      return payload
    default:
      return state
  }
}

const SET_REVIEW_ADDED = 'SET_REVIEW_ADDED'
const setReviewAdded = createAction(SET_REVIEW_ADDED)

function reviewAdded(state = false, { type, payload }) {
  switch (type) {
    case SET_REVIEW_ADDED:
      return payload
    default:
      return state
  }
}

const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS'
const setMapBounds = createAction(SET_MAP_BOUNDS)

function mapBounds(state = window.locationBounds || {}, { type, payload }) {
  switch (type) {
    case SET_MAP_BOUNDS:
      return payload
    default:
      return state
  }
}

const SET_PAGE = 'SET_PAGE'
const setPage = createAction(SET_PAGE)

function page(state = parseInt(queryParams.page || 1, 10), { type, payload }) {
  switch (type) {
    case SET_PAGE:
      return payload
    default:
      return state
  }
}

const SET_MAP_MOVED = 'SET_MAP_MOVED'
const setMapMoved = createAction(SET_MAP_MOVED)

function isMapMoved(state = false, { type, payload }) {
  switch (type) {
    case SET_MAP_MOVED:
      return payload
    default:
      return state
  }
}

const SET_USER_COORDINATES = 'SET_USER_COORDINATES'
const setUserCoordinates = createAction(SET_USER_COORDINATES)

function userCoordinates(state = false, { type, payload }) {
  switch (type) {
    case SET_USER_COORDINATES:
      return payload
    default:
      return state
  }
}

export {
  setSelectedPin,
  selectedPin,
  setFilters,
  mapFilters,
  setTabType,
  selectedTab,
  setReviewAdded,
  reviewAdded,
  updateFilters,
  setMapBounds,
  mapBounds,
  page,
  setPage,
  setMapMoved,
  isMapMoved,
  setUserCoordinates,
  userCoordinates,
  opennedMarker,
  setOpennedMarker,
  shopInfo,
  setShopInfo,
  isMapVisible,
  setIsMapVisible,
}
