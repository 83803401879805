import { useMemo } from 'react'
import { FormattedMessage, defineMessages } from 'react-intl'
import get from 'lodash/get'

const messages = defineMessages({
  numberOfDives: {
    id: 'number_of_dives_activities_inclusions',
    defaultMessage: '{numberOfDives, plural, one {# dive} other {# dives}}',
  },
  courseInclusionDays: {
    id: 'course_days_activity_inclusions',
    defaultMessage:
      '{numberOfDays, plural, one {# day} other {# days}}, incl. {numberOfDives, plural, one {# dive} other {# dives}}',
  },
  courseInclusionHours: {
    id: 'course_hours_adventure_inclusion',
    defaultMessage:
      '{numberOfHours, plural, one {# hour} other {# hours}}, incl. {numberOfDives, plural, one {# dive} other {# dives}}',
  },
})

export function ActivityInclusionsSearchPage({ item, activityType }) {
  const { numberOfDays, numberOfDives, numberOfHours } = item
  const message = useMemo(() => {
    if(activityType === 'dive-trips' || activityType === 'social_event') {
      return (
        <FormattedMessage
          {...messages.numberOfDives}
          values={{
            numberOfDives: item.numberOfDives,
          }}
        />
      )
    }
    if(activityType === 'courses') {
      const type = get(item, 'duration.durationType.value')
      const duration = get(item, 'duration.duration')
      if(type === 20) {
        return (
          <FormattedMessage
            {...messages.courseInclusionDays}
            values={{
              numberOfDays: duration,
              numberOfDives: item.numberOfDives,
            }}
          />
        )
      }
      return (
        <FormattedMessage
          {...messages.courseInclusionHours}
          values={{
            numberOfHours: duration,
            numberOfDives: item.numberOfDives,
          }}
        />
      )
    }
    return null
  }, [item.activityType, numberOfDays, numberOfDives, numberOfHours])
  if(!message) {
    return null
  }
  return message
}

export default function ActivityInclusions({ item }) {
  const {
    numberOfDays,
    numberOfDives,
    numberOfHours,
    activityType,
    durationHours,
  } = item
  const message = useMemo(() => {
    if(activityType === 'dive_trip' || activityType === 'social_event') {
      return (
        <FormattedMessage
          {...messages.numberOfDives}
          values={{
            numberOfDives: numberOfDives,
          }}
        />
      )
    }
    if(activityType === 'course') {
      if(durationHours >= 24) {
        return (
          <FormattedMessage
            {...messages.courseInclusionDays}
            values={{
              numberOfDays: durationHours / 24,
              numberOfDives: numberOfDives,
            }}
          />
        )
      }
      return (
        <FormattedMessage
          {...messages.courseInclusionHours}
          values={{
            numberOfHours: durationHours,
            numberOfDives: numberOfDives,
          }}
        />
      )
    }
    return null
  }, [activityType, numberOfDays, numberOfDives, numberOfHours, durationHours])
  if(!message) {
    return null
  }
  return message
}
