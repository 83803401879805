import { defineMessages, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Fragment, useMemo } from 'react'
import { APP_LANGUAGE } from '../../localized-urls'

const AVAILABILITY_FORMAT = 'YYYY-MM-DD' // the format of date that comes from API

const getAvailabilityText = (date) => {
  // function that helps to determine the availability of the activity
  // e.g. in case it's not available today or tomorrow we display the exact date
  // otherwise the translated string should be displayed with different design
  const todayDate = moment().format(AVAILABILITY_FORMAT)
  const tomorrowDate = moment().add(1, 'days').format(AVAILABILITY_FORMAT)

  if(date === todayDate) {
    return 'today'
  }
  if(date === tomorrowDate) {
    return 'tomorrow'
  }
  return 'otherDate'
}

const messages = defineMessages({
  today: {
    id: 'availability.today',
    defaultMessage: 'Next available today',
  },
  tomorrow: {
    id: 'availability.tomorrow',
    defaultMessage: 'Next available tomorrow',
  },
  otherDate: {
    id: 'availability.otherDate',
    defaultMessage: 'Next available on {date}',
  },
  elearning: {
    id: 'availability.elearning',
    defaultMessage: 'Contact shop for dates',
  },
})

export default function AvailableDates({ date, infoType }) {
  const dateMessage = useMemo(() => {
    const availability = getAvailabilityText(date)
    return messages[availability]
  }, [date])

  if(infoType === 'elearning') {
    return <FormattedMessage {...messages.elearning} />
  }

  return (
    <Fragment>
      <FormattedMessage
        {...dateMessage}
        values={{
          date: moment(date).locale(APP_LANGUAGE).format('MMM. D'),
        }}
      />
    </Fragment>
  )
}
