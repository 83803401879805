import { Fragment } from 'react'
import { isLoggedIn } from '../../../utils'
import store2 from 'store2'
import { FormattedMessage } from 'react-intl'

export default function PadiHeaderAccountAvatar() {
  const isUserAuthenticated = isLoggedIn()
  const user = store2.get('user')
  return (
    isUserAuthenticated
      ? (
        <Fragment><img className='padi-nav-header__profile-picture' src={user?.avatar?.HeaderAvatar} />
          <span className='padi-nav-header__title-with-icon'>{user?.firstName}</span></Fragment>)
      : (<Fragment>
        <i className='padi-nav-header__icon padi-icons padi-icons--single-user' />
        <span className='padi-nav-header__title-with-icon'><FormattedMessage defaultMessage='Sing In' /></span>
      </Fragment>)

  )
}
