import { FormattedMessage } from 'react-intl'
import {
  createPadiLearningLocalizedUrl,
  createUrl,
} from '../../../localized-urls'
import PropTypes from 'prop-types'

export default function UserLoggedIn({ user }) {
  const { avatar, firstName, lastName, diveExperience } = user
  return (
    <div className='padi-nav-header__menu-wrapper'>
      <div className='padi-nav-header__menu-column padi-nav-header__menu-column_logged-user'>
        <ul className='padi-nav-header__menu-list'>
          <li className='padi-nav-header__menu-list-item padi-nav-header__menu-list-item_title'>
            <FormattedMessage defaultMessage='My Profile' />
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createPadiLearningLocalizedUrl('account')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Login & Password' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createPadiLearningLocalizedUrl('preferences')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Settings & Preferences' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createUrl('logout', null, '?source=padi-nav-header')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Log Out' />
            </a>
          </li>
        </ul>
      </div>
      <div className='padi-nav-header__menu-column padi-nav-header__menu-column_logged-user'>
        <a
          data-navigation-elements='data-navigation-elements'
          data-region-identifier='menu-main-account-student'
          href={createPadiLearningLocalizedUrl('account')}
          className='padi-nav-header__user-section'
        >
          <img
            loading='lazy'
            className='padi-nav-header__user-section-avatar'
            src={avatar?.HeaderAvatar}
          />
          <div className='padi-nav-header__user-details'>
            <span className='padi-nav-header__user-name'>
              {firstName} {lastName}
            </span>
            {diveExperience?.certificationLevel && (
              <span className='padi-nav-header__user-role'>
                {diveExperience?.certificationLevelTitle}
              </span>
            )}
          </div>
        </a>
        <ul className='padi-nav-header__menu-list padi-nav-header__menu-list_with-separator-mobile'>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createPadiLearningLocalizedUrl('certifications')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Certifications' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createPadiLearningLocalizedUrl('dashboard')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='eLearning' />
            </a>
          </li>
          <li className='padi-nav-header__menu-list-item'>
            <a
              data-navigation-elements='data-navigation-elements'
              data-region-identifier='menu-main-account-student'
              href={createPadiLearningLocalizedUrl('logbook')}
              className='padi-nav-header__menu-list-item-link'
            >
              <FormattedMessage defaultMessage='Logbook' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

UserLoggedIn.propTypes = {
  user: PropTypes.object,
}
